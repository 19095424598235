import React from 'react';

import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import '@contently/videojs-annotation-comments/build/css/annotations.css';

import AnnotationComments from '@contently/videojs-annotation-comments'
videojs.registerPlugin('annotationComments', AnnotationComments(videojs))

export const VideoJS = (props) => {
    const videoRef = React.useRef(null);
    const playerRef = React.useRef(null);
    const {options, onReady} = props;

    var plugin;

    React.useEffect(() => {

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement("video-js");

            videoElement.classList.add('vjs-big-play-centered');
            videoRef.current.appendChild(videoElement);



            const player = playerRef.current = videojs(videoElement, options, () => {
                videojs.log('player is ready');
                onReady && onReady(player);



            });


            const annotationsObjects = [{
                id: 1,
                range: {
                    start: 10,
                    end: 35
                },
                shape: {
                    x1: 23.47,
                    y1: 9.88,
                    x2: 60.83,
                    y2: 44.2
                },
                comments: [{
                    id: 1,
                    meta: {
                        datetime: '2017-03-28T19:17:32.238Z',
                        user_id: 1,
                        user_name: 'Jack Pope'
                    },
                    body: 'The first comment!'
                }]
            }];


            const pluginOptions = {
                // Collection of annotation data to initialize
                annotationsObjects: annotationsObjects,
                // Flexible meta data object (currently used for user data, but addl data can be provided to wrap each comment with metadata - provide the id of the current user and fullname of the current user at minimum, which are required for the UI)
                meta: { user_id: null, user_name: null },
                // Use arrow keys to move through annotations when Annotation mode is active
                bindArrowKeys: true,
                // Show or hide the control panel and annotation toggle button (NOTE - if controls are hidden you must provide custom UI and events to drive the annotations - more on that in "Programmatic Control" below)
                showControls: true,
                // Show or hide the comment list when an annotation is active. If false, the text 'Click and drag to select', will follow the cursor during annotation mode
                showCommentList: true,
                // If false, annotations mode will be disabled in fullscreen
                showFullScreen: false,
                // Show or hide the tooltips with comment preview, and annotation shape, on marker hover or timeline activate
                showMarkerShapeAndTooltips: true,
                // If false, step two of adding annotations (writing and saving the comment) will be disabled
                internalCommenting: true,
                // If true, toggle the player to annotation mode immediately after init. (NOTE - "annotationModeEnabled" event is not fired for this initial state)
                startInAnnotationMode: false
            };


            plugin = player.annotationComments(pluginOptions)




            // You could update an existing player in the `else` block here
            // on prop change, for example:
        } else {
            const player = playerRef.current;

            player.autoplay(options.autoplay);
            player.src(options.sources);
        }
    }, [options, videoRef]);

    // Dispose the Video.js player when the functional component unmounts
    React.useEffect(() => {
        const player = playerRef.current;

        return () => {
            if (player && !player.isDisposed()) {
                player.dispose();
                playerRef.current = null;
            }
        };
    }, [playerRef]);

    return (
        <div data-vjs-player>
    <div ref={videoRef} />
    </div>
);
}

export default VideoJS;