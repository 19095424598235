import * as React from 'react';
import { useState,useRef } from "react";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import Alert from '@mui/material/Alert';


function SNChecker () {

    const [nameToCheck, setNameToCheck] = useState('');
    const [checkPassed,setCheckPassed] = useState(false);
    const [nameChanged,setNameChanged] = useState(true);


    const generateChecksum = (inputStr) => {

        //a-z is charspace of checksum , corresponding to String.fromCharCode(97, (...) 122)
        // 26 * 26 is 676
        let charCodeSum=0;
        for (let n=0;n<inputStr.length;n++)
            charCodeSum+=inputStr.charCodeAt(n);

        let normalizedSum=charCodeSum % 676;
        let firstFactor = normalizedSum % 26;
        let secondFactor = Math.floor(normalizedSum/26);

        return String.fromCharCode(firstFactor+97,secondFactor+97);

    }



    const checkScreenname = () => {
        setNameChanged(false);
        let checkpassed=false;
        try {
        let firstLetter = nameToCheck[0];
        let secondLetter=nameToCheck[1];
        let restStr=nameToCheck.slice(2);

        let checksum=generateChecksum(restStr);

        if (checksum[0]==firstLetter && checksum[1]==secondLetter)
            checkpassed=true;

            }
            catch (e)
            {
        ;
            }

          setCheckPassed(checkpassed);

    };


    return (
        <div>
        <Paper>
        <Typography variant="h2">
        S/N Checker
    </Typography>


        <TextField
    id="outlined-controlled"
    label="S/N"
    autofocus
    helperText="Screen name to check"
    variant="filled"

    value={nameToCheck}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setNameChanged(true);
        setNameToCheck(event.target.value);
    }}
    />

    <Button variant="contained" onClick={checkScreenname}>Check</Button>




        <div>
        { (nameToCheck.length>2 && (!nameChanged)) &&

        <Alert severity={checkPassed?"success":"error"} >

                <Typography variant="h4">
            {nameToCheck}
            </Typography>
            {checkPassed?
            "Very likely KRW group member":
                    "Player did not use KRW ID generation"

            }

                </Alert>



        }
        </div>

</Paper>
    </div>

);
}


export default SNChecker;