//import crypto  from "crypto-browserify";


import * as React from 'react';
import { useState,useRef } from "react";


import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Link from '@mui/material/Link';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const charsetConsonants= ['b','c','d','f','g','h','j','k','l','m','n','p','q','r','s','t','v','w','x','z']
const charsetVowels=['a','e','i','o','u','y'];
const charsetNumbers=['0','1','2','3','4','5','6','7','8','9'];

//const hash = crypto.createHmac("sha256",'69KRW420');
const hangulStart=44032;
const hangulLength=11183;


function SNGenerator () {

    const [name, setName] = useState('');

    const [genMode,setGenMode] = useState("charset");
    const [desiredSNLength,setDesiredSNLength] = useState([8,10]);

    const [checkedConsonants,setCheckedConsonants]= useState(true);
    const [checkedVowels,setCheckedVowels]= useState(false);
    const [checkedNumbers,setCheckedNumbers]= useState(false);

    const [checkedChecksum,setCheckedChecksum]=useState(true);
    const [checkedNumberSuffix,setCheckedNumberSuffix]=useState(false);

    const [numberSuffixLength,setNumberSuffixLength]=useState([1,2]);

    const [generatedNames,setGeneratedNames]=useState([]);

    const [notesObj,setNotesObj] = useState([]);
    const [curNotes,setCurNotes] = useState("");


    const handleChangeGenMode = (event) => {
        setGenMode(event.target.value);
    };
    const handleChangeSNLength = (event) => {
        setDesiredSNLength(event.target.value);
    };


    const handleChangeCConsonants= (event) => {setCheckedConsonants(event.target.checked)}
    const handleChangeCVowels= (event) => {setCheckedVowels(event.target.checked)}
    const handleChangeCNumbers= (event) => {setCheckedNumbers(event.target.checked)}

    const handleChangeCChecksum = (event) => {setCheckedChecksum(event.target.checked)}
    const handleChangeCNumberSuffix = (event) => {setCheckedNumberSuffix(event.target.checked)}

    const handleChangeNumberSuffixLength = (event) => {setNumberSuffixLength(event.target.value)}


    const generateChecksumFTAlgo = (inputStr) => {


        //   hash.update(inputStr);
        //     let hashedInput=hash.digest("base64");
        let hashedInput=inputStr;

        //a-z is charspace of checksum , corresponding to String.fromCharCode(97, (...) 122)
        // 26 * 26 is 676



        let charCodeSum=0;
        for (let n=0;n<hashedInput.length;n++)
            charCodeSum+=hashedInput.charCodeAt(n);

        let normalizedSum=charCodeSum % hangulLength;


        return String.fromCharCode(hangulStart+ normalizedSum);


    }

    const generateChecksum = (inputStr) => {



     //   hash.update(inputStr);
   //     let hashedInput=hash.digest("base64");
        let hashedInput=inputStr;

        //a-z is charspace of checksum , corresponding to String.fromCharCode(97, (...) 122)
        // 26 * 26 is 676



        let charCodeSum=0;
        for (let n=0;n<hashedInput.length;n++)
            charCodeSum+=hashedInput.charCodeAt(n);

        let normalizedSum=charCodeSum % 676;
        let firstFactor = normalizedSum % 26;
        let secondFactor = Math.floor(normalizedSum/26);

        return String.fromCharCode(firstFactor+97,secondFactor+97);

    }

const generateNameFTAlgo = ((numToGenerate=1) => {

    let newSNArray=JSON.parse(JSON.stringify(generatedNames))
    //check if settings valid

    for (let n =0;n<numToGenerate;n++)
    {
        let resultStr="";

        let charsToGen=-1;

        // determined chars added
        let charsAdded = Math.round(desiredSNLength[0] + ( Math.random() * (desiredSNLength[1]-desiredSNLength[0])));

        charsToGen+=charsAdded;
        if (charsToGen>0)
        {

            for (let x=0;x<charsToGen;x++)
            {
                let randindex = Math.round(Math.random()*hangulLength)
                resultStr+=String.fromCharCode(hangulStart+randindex);

            }





        }


        resultStr = generateChecksumFTAlgo(resultStr) + resultStr;
        newSNArray.push(resultStr);

    }
    setGeneratedNames(newSNArray)



});


    const generateName = ((numToGenerate=1) => {
 if (genMode=="toothpick")
 {
 generateNameFTAlgo(numToGenerate);
     return;
 }
    
        let newSNArray=JSON.parse(JSON.stringify(generatedNames))
        //check if settings valid

    for (let n =0;n<numToGenerate;n++)
    {
        let resultStr="";
       //calc numbers of chars to generate
        let numbersAdded= Math.round(numberSuffixLength[0] + ( Math.random() * (numberSuffixLength[1]-numberSuffixLength[0])));

       let charsToGen=0;
       if (checkedChecksum)
           charsToGen -= 2;
       if (checkedNumberSuffix)
           charsToGen -=numbersAdded

    // determined chars added
        let charsAdded = Math.round(desiredSNLength[0] + ( Math.random() * (desiredSNLength[1]-desiredSNLength[0])));

       charsToGen+=charsAdded;
       if (charsToGen>0)
       {
           let charspace=[];
           if (checkedConsonants)
               charspace.push(...charsetConsonants);
           if (checkedVowels)
               charspace.push(...charsetVowels);
           if (checkedNumbers)
               charspace.push(...charsetNumbers);

           for (let x=0;x<charsToGen;x++)
           {
               let randindex = Math.round(Math.random()*(charspace.length-1))
            resultStr+=charspace[randindex];

           }





       }

        if (checkedNumberSuffix) {
            for (let x = 0; x < numbersAdded; x++) {
                let randindex = Math.round(Math.random() * 9)
                resultStr += charsetNumbers[randindex];


            }
        }
         resultStr = generateChecksum(resultStr) + resultStr;
        newSNArray.push(resultStr);

        }
    setGeneratedNames(newSNArray)



}).bind(this);


    return (
        <div>
        <Paper>

<Typography variant="h2">
        S/N Generator
        </Typography>


    <FormControl>


    <TextField
    id="outlined-controlled"
    label="Username"
    helperText="(DISABLED) First 3+3 letters of name"
    variant="filled"
    disabled={true}
    value={name}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }}
    />




       <Card>
    <Typography variant="h3"> Results </Typography>
    <div>
        <List>
        {generatedNames.map((name) => (

            <ListItem>
                {name}
                </ListItem>

                ))
        }

        </List>

        </div>

        <Button variant="contained" onClick={() => generateName(1)}>Generate</Button>



    </Card>







        <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    >
    <Typography variant="h3"> Settings </Typography>
        </AccordionSummary>
        <AccordionDetails>
    <Card>
    <FormLabel id="desiredLengthLabel"><Typography variant="h4">Desired length</Typography></FormLabel>
    <Slider
    getAriaLabel={() => 'Desired length'}
    aria-labelledby="desiredLengthLabel"
    value={desiredSNLength}
    onChange={handleChangeSNLength}
    valueLabelDisplay="auto"
    min={4} max={16}
    />

        <Typography variant="h6">
        Min : {desiredSNLength[0]} / Max : {desiredSNLength[1]}
        </Typography>
</Card>


    <FormLabel id="genModeRadioLabel"><Typography variant="h4">Generation mode</Typography></FormLabel>

    <RadioGroup
    aria-labelledby="genModeRadioLabel"
    defaultValue="charset"
    name="genModeRadio"
        value={genMode}
        onChange={handleChangeGenMode}
        >


        <Accordion defaultExpanded>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
        >

        <FormControlLabel value="charset" control={<Radio />} label="Charset-based" />



        </AccordionSummary>
    <AccordionDetails>

        <Stack>
        <FormControlLabel label="Include consonants" control= {
        < Checkbox
    checked = {checkedConsonants}
    onChange = {handleChangeCConsonants}
    />} />


    <FormControlLabel label="Include vowels" control= {
        < Checkbox
    checked = {checkedVowels}
    onChange = {handleChangeCVowels}
    />} />

    <FormControlLabel label="Include numbers" control= {
        < Checkbox
    checked = {checkedNumbers}
    onChange = {handleChangeCNumbers}
    />} />



    <FormControlLabel label="Magic KRW ID (recommended)" control= {
        < Checkbox
    checked = {checkedChecksum}
    onChange = {handleChangeCChecksum}
    />} />


    <FormControlLabel label="Number suffix" control= {
        < Checkbox
    checked = {checkedNumberSuffix}
    onChange = {handleChangeCNumberSuffix}
    />} />

    <FormLabel id="desiredNumberSuffixLengthLabel"><Typography variant="h6">Desired suffix length</Typography></FormLabel>

    <Slider
    disabled = {!checkedNumberSuffix}
    getAriaLabel={() => 'Desired suffix length'}
    aria-labelledby="desiredNumberSuffixLengthLabel"
    value={numberSuffixLength}
    onChange={handleChangeNumberSuffixLength}
    valueLabelDisplay="auto"
    min={1} max={4}
    />






    </Stack>
    </AccordionDetails>
    </Accordion>


    <Accordion>
    <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    aria-controls="panel1-content"
    id="panel1-header"
        >

        <FormControlLabel value="ruleset" control={<Radio disabled={true}/>} label="Rule-based" />



        </AccordionSummary>
        <AccordionDetails>
        <Typography>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
    malesuada lacus ex, sit amet blandit leo lobortis eget.
    </Typography>
    </AccordionDetails>
    </Accordion>



<Accordion>
<AccordionSummary
expandIcon={<ExpandMoreIcon />}
aria-controls="panel1-content"
id="panel1-header"
    >

    <FormControlLabel value="toothpick" control={<Radio />} label="Fried toothpick algorithm" />



</AccordionSummary>
<AccordionDetails>
<Typography>
Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
malesuada lacus ex, sit amet blandit leo lobortis eget.
</Typography>
</AccordionDetails>
</Accordion>



</RadioGroup>

<Stack>


</Stack>


</AccordionDetails>
</Accordion>

   


    </FormControl>
</Paper>
    </div>

);
}

export default SNGenerator;