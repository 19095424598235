//import crypto  from "crypto-browserify";


import * as React from 'react';
import { useState,useRef,useEffect } from "react";
import axios from "axios";

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Link from '@mui/material/Link';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';

import Pagination from '@mui/material/Pagination';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import MaterialTable from 'material-table'
import { ThemeProvider, createTheme } from '@mui/material';

let apiUrl="https://api.krw.lol";


function CoachRB () {

    const [curIndex, setCurIndex] = useState(-1);

    const [snapshots,setSnapshots] = useState([]);


    const handlePageChange = (event, value) => {
        setCurIndex(value-1);
    };

    const fetchData = (() => {
        axios.get(`${apiUrl}/coachRB`)
        .then(res => { // then print response status

console.log(res.data.snapshots);
//console.error( res.json());
setCurIndex((res.data.snapshots.length) - 1)        

            setSnapshots(res.data.snapshots);

        })
});
   
    

    useEffect(() => {
        fetchData();
    }, []);

        const defaultMaterialTheme = createTheme();


    return (
        <div>
        <Paper>

<Typography variant="h2">
Coach Rakeback Tracking
        </Typography>


    <FormControl style={{width:"100%"}}>


       <Card>
    
      { (curIndex>=0 && snapshots.length>curIndex) &&
        <div>
              <Pagination count={snapshots.length} page={curIndex+1} onChange={handlePageChange} />
                            <ThemeProvider theme={defaultMaterialTheme}>

        <MaterialTable
options = {{pageSize:20}}
        columns={[
            { title: 'Player ID', field: 'ID' ,type:"numeric"},

            { title: 'Login', field: 'login' },
            { title: 'Balance', field: 'balance', type:"numeric" },
            { title: 'Rakeback to Account', field: 'rakebacksixty', type: 'numeric' },
            { title: '10% RB', field: 'rakebackten' }
          ]}

          data={Object.entries(snapshots[curIndex].accounts).map( (obj) =>
        { return obj[1]})}
          title={snapshots[curIndex].datetime}
 />
                 </ThemeProvider>

        <Typography variant="h4"> {snapshots[curIndex].datetime} </Typography>
  
        </div>

    }




    </Card>


    </FormControl>
</Paper>
    </div>

);
}

export default CoachRB;