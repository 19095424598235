import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import MovUpload from './MovUpload'
import LocalPlayer from './LocalPlayer'
import SNGenerator from './SNGenerator'
import SNChecker from './SNChecker'
import CoachRB from './CoachRB'

import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


const router = createBrowserRouter([
  {
    path: "/",
    element:<App />,
},
{
  path: "/upload",
      element: <MovUpload />,

},
{
  path: "/local",
      element: <LocalPlayer />,

},

{
path: "/screenname",
element: <SNGenerator />,
},

{
  path: "/sncheck",
      element: <SNChecker />,
},

{
path : "/coachRB",
element: <CoachRB />,


}

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
<RouterProvider router={router} />

</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
