import { useState } from "react";

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';


let apiUrl="https://api.krw.lol";

function MovUpload() {

    const [progress, setProgress] = useState(0);
    const [name, setName] = useState('');


   let uploadFile= ((event) => {
        const data = new FormData();
       //alert (JSON.stringify(event.files[0]));
        //alert (JSON.stringify(event.target.files[0].name));

       data.append('user',name);
       data.append('fileName',event.target.files[0].name);
       data.append('file', event.target.files[0],event.target.files[0].name);

        axios.post(`${apiUrl}/uploadFileAPI`, data,
            { onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    setProgress(progress);
                },
            })
            .then(res => { // then print response status
                console.log(res.statusText)
            })
    });

    return (
      <div>

        <TextField
    id="outlined-controlled"
    label="Username"
    helperText="First 3+3 letters of name"
    variant="filled"
    value={name}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }}
    />

        <div>
      <h1> Upload </h1>
        <input type="file" onChange={uploadFile} />
</div>
    <CircularProgress variant="determinate" value={progress} />
    {progress>=100 ? (<div> Upload done! </div>) : null }

    </div>

    );
}

export default MovUpload;