import logo from './logo.svg';
import toothpick from "./toothpick.jpg";
import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { Link } from "react-router-dom";
//import { Icon} from '@mui/material';
import Icon from '@mui/material/Icon';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import * as MUIcon from '@mui/icons-material'

function App() {

    const appLinkList= [
        {label:"Local video player",href:"/local",icon:"OndemandVideo"},

        {label:"S/N Generator",href:"/screenname",icon:"PersonAdd"},
        {label:"S/N Checker",href:"/sncheck",icon:"HowToReg"},


        {label:"Upload file",href:"/upload",icon:"Upload"}


    ];


    const [drawerOpen, setDrawerOpen] = React.useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setDrawerOpen(newOpen);
    };


    const MIcon = (props) =>  {
        const { name } = props
        const Icon = MUIcon[name]
        if (Icon == null) {
            throw `There is no "${name}" Icon`
        }
        return <Icon {...props} />
    }

    const menuList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
<List>
    {appLinkList.map((obj, index) => (


<div>
        {index==3? <Divider />: null}
        <ListItem key={obj.href} disablePadding>
        <Link to={obj.href}>
    <ListItemButton>
    <ListItemIcon>
    {MIcon({name: obj.icon})}
</ListItemIcon>
    <ListItemText primary={obj.label} />
    </ListItemButton>
        </Link>
    </ListItem>

</div>

))}
</List>

    </Box>
);

/*

   <Divider />
    <List>
    {['All mail', 'Trash', 'Spam'].map((text, index) => (
        <ListItem key={text} disablePadding>
    <ListItemButton>
    <ListItemIcon>
    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
</ListItemIcon>
    <ListItemText primary={text} />
    </ListItemButton>
    </ListItem>
))}
</List>
 */





    return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
    {menuList}
</Drawer>
        <Button variant="contained" size="large" onClick={toggleDrawer(true)}>Open menu</Button>
        <p>
          <img src="/toothpick.jpg" style={{width:"95%",borderRadius:10}} />
        </p>






      </header>
    </div>
  );
}

export default App;
