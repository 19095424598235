import { useState,useRef } from "react";
import VideoJS from './VideoJS'

import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';




let apiUrl="http://api.krw.lol";

function LocalPlayer() {

    const [progress, setProgress] = useState(0);
    const [name, setName] = useState('');

    const [notesObj,setNotesObj] = useState([]);
    const [curNotes,setCurNotes] = useState("");

    const playerRef = useRef(null);
   // const notesTable=useRef(null);

    let uploadFile= ((event) => {


     //   data.append('file', event.target.files[0],event.target.files[0].name);
        var tmppath = URL.createObjectURL(event.target.files[0]);
        playerRef.current.src({ type: 'video/mp4', src: tmppath });


    });

    let setVideoTime = (targetTime) => {
        playerRef.current.currentTime(targetTime);
    }

    let updateMarkers = (newMarkers) => {
        newMarkers.sort(

            (a,b) => {
              return a.time - b.time;

            }

        )
        setNotesObj(newMarkers);
    }

    let addMarker = () => {
       let tmpMarkers = JSON.parse(JSON.stringify(notesObj));
       let curTimestamp= playerRef.current.currentTime();
       tmpMarkers.push({time:curTimestamp,notes:curNotes});

       updateMarkers(tmpMarkers);

    };

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
    };
    const handlePlayerReady = (player) => {
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
       //     videojs.log('player is waiting');
        });

        player.on('dispose', () => {
         //   videojs.log('player will dispose');
        });
    };

    return (
        <div>

        <TextField
    id="outlined-controlled"
    label="Username"
    helperText="First 3+3 letters of name"
    variant="filled"
    value={name}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    }}
    />

    <div>
    <h1> Select local file </h1>
    <input type="file" onChange={uploadFile} />
    </div>
        <div style={{width:"80%"}}>
    <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
</div>

        <div style={{marginTop:20}}>

    <TextField
    id="filled-textarea"
    label="Comments"
    placeholder="Put thoughts and comments here"
    multiline
    variant="filled"
    value={curNotes}
    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setCurNotes(event.target.value);
    }}
        />

        <Stack direction="row" spacing={2}>

        <Button variant="contained" onClick={addMarker}>Mark Spot</Button>

    </Stack>

</div>

        <h2> Notes </h2>

    <TableContainer component={Paper}>
        <Table sx={{ minWidth: 350 }} aria-label="simple table">
        <TableHead>
        <TableRow>
        <TableCell>Time</TableCell>
        <TableCell >Notes</TableCell> </TableRow>
    </TableHead>
    <TableBody>
    {notesObj.map((row) => (
            <TableRow
        key={row.time}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
>
<TableCell component="th" scope="row">

        <Link onClick={() => setVideoTime(row.time)}>

    {( new Date(Math.floor(row.time*1000)).toISOString().slice(11,-5))}
</Link>
        </TableCell>
        <TableCell >{row.notes}</TableCell>
</TableRow>
))}
</TableBody>
    </Table>
    </TableContainer>





    </div>

    );
    }

    export default LocalPlayer;